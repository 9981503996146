







import { Component, Mixins } from 'vue-property-decorator'

import UserInformationForm from '@/components/forms/users/UserInformationForm.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerUsersModule from '@/store/modules/manager/users'

@Component({
  components: {
    UserInformationForm,
  },
})
export default class MentorsItemInformation extends Mixins(PermissionsMixin) {
  private get user() {
    return ManagerUsersModule.user
  }
}
